import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import ImageTwo from '../components/image'
import SEO from "../components/seo"
import "../components/layout.css"
const IndexPage = () => (
  <Layout>

    <SEO title="JRAT" />
  <div class="spacingdiv" style={{backgroundColor:"#4f1416", color:"white"}}>
<span class="a">
    <nav class="d">

        <ul class = "d">

          <li class ="ab">
            <Link class="d" to="/about/">Our Team</Link>
          </li>
          <li>
          <a class ="d" target="_blank" href="https://dl.acm.org/doi/pdf/10.1145/3351095.3372859">
          Paper
          </a>
          </li>
          <li class="ab"><Link class="d" to="/studyguide/">Study Guide</Link>
          </li>
  <li><a class="d"  target="_blank" href="https://docs.google.com/document/d/e/2PACX-1vTOjmDqOZyi9XxC5Hiu9uur82WEUk_IGDcWLyBgj4IibAf-WA5QPaLD1qd7kwihCwKvfHaLqiyArTxI/pub">Development</a></li>

          <li><a class="d" target="_blank" href="http://rkarkarka.pythonanywhere.com/">Demo</a></li>
          <li>
        <Link class="d" to="/casestudy">Case Study</Link>   </li>

          <li>
    <Link class="d" to="/">Study Up</Link>   </li>


        </ul>

      </nav>
      </span>
      </div>


<div style={{backgroundColor:"#4f1416"}}>
<div class="graphImage1">
    <ImageTwo file="slide7.png" />
    </div>
    </div>
    <div style={{backgroundColor:"#163b4a"}}>
<div class="graphImage2">
    <ImageTwo file="slide8.png" />
    </div>

    <div style={{backgroundColor:"#e3e3e3"}}>
    <div class="maptext" style={{color:"white"}}>
    <p style={{color:"#163b4a"}}> <h1>

<b>  Pretrial risk assessments have emerged as an apparent solution to this crisis.</b> The hope is that, by using large datasets to predict future arrest, these algorithms can help reduce the number of people incarcerated pretrial and lower racial disparities.

         </h1></p>

<div class="graphImage3">
    <ImageTwo file="map.png" />
    </div>
    </div>
    </div>
    <div class="row" style={{backgroundColor:"#247b9f"}}>
     <div class="column">
     <div class="judgeImage">
     <ImageTwo file="slide11.png" />
     </div>
     </div>


     <div class="column" >
     <div class="vertical-center">
     <div class="textphone">
     <div
     data-sal="slide-down"
     data-sal-delay="200"
     data-sal-easing="ease"
     >
     <p style={{color:"white"}}><h1>

</h1></p>
<div class="vertical-center10">
<div style={{color:"white"}}>
<p align="left" style={{color:"white"}}> <h1>

But to date, risk assessments haven’t delivered on that promise. <a href="https://dam-prod.media.mit.edu/x/2019/07/16/TechnicalFlawsOfPretrial_ML%20site.pdf?source=post_page---------------------------" ><u>Studies have shown</u> </a>that risk assessments aren’t that accurate, and they’re biased against Black people.

     </h1></p>
     </div>
     </div>
     </div>
     </div>
     </div> </div></div>


            <div class="row" style={{backgroundColor:"#644462"}}>
             <div class="column">
<div class="format">
             <ImageTwo file="slide13.png" />
</div>
             </div>
             <div class="column" >
             <div class="vertical-center">
             <div
             data-sal="slide-down"
             data-sal-delay="300"
             data-sal-easing="ease"
             >
             <p align="left" style={{color:"white"}}><h1>But risk assessments also represent a more fundamental problem — our tendency to use technology to monitor and control the behavior of marginalized groups, rather than to hold those in power accountable.
             </h1></p>
             </div>
             </div> </div></div>
             <div class="row" style={{backgroundColor:"#7D7C84"}}>
              <div class="column">
<div class="format">

              <ImageTwo file="slide12.png" />
              </div>
              </div>

              <div class="column" >
              <div class="vertical-center">
              <div class="recenter">
              <p align="left" style={{color:"white"}}><h1> Mass pretrial incarceration is driven by judges who unlawfully send people to jail. Yet current algorithms focus exclusively on the behavior of people awaiting trial, rather than on the behavior of judges. <b>An algorithm that <b> "studies up"</b> would make judges the focus of predictive modeling.</b>
              </h1></p>
</div>
              </div> </div></div>
              <div class="smallimg">
              <div class="row" style={{backgroundColor:"#247B9F"}}>

              <div class="column">

              <ImageTwo file="slide14.png" />

              </div>
  <div class="s">
              <div class="column6">

              <p align="left" style={{color:"white"}}><h1><b>Our team created a judicial risk assessment tool. </b><br /><br/>The algorithm assigns judges a risk score based on their likelihood of failing to adhere to the Constitution by unlawfully incarcerating someone pretrial.

              </h1></p>
              </div>
              </div>

              </div>
              </div>
              <div class="full" style={{backgroundColor:"#e3e3e3"}}>
              <div class="description">
              <div class="textspace">
                <p align="center" style={{color:"#38717d"}}><h1> <b> Check out the <a href="http://rkarkarka.pythonanywhere.com/"><u> Judicial Risk Assesment Tool Demo</u></a></b> </h1> </p>
</div>
<div class="judgeImage">
              <ImageTwo file="JRATcomputer.png" />
              </div>
              </div>
          </div>
              <div align="center" style={{backgroundColor:"#e3e3e3"}}>

              <div class="description3" style={{backgroundColor:"#e3e3e3"}}>
              <p style={{color:"#38717d"}}><h1>This tool relies on court records and demographic data. Like other risk assessments, <b> age </b> is the most predictive factor.</h1> </p> </div>
              </div>
              <div class="row" style={{backgroundColor:"#153949"}}>
<div class="graphImage4">
               <ImageTwo file="slide16.png" />
</div>


               <p align="center" style={{color:"white"}}><h1>Our algorithm is both more transparent and accurate than pretrial risk assessments currently in use.
              </h1></p>

               </div>
               <div class="column7" style={{backgroundColor:"#3f7fa2"}}>
               <p style={{color:"white"}}><h1>
            <b>   The judicial risk assessment tool is not intended for practical use in the courts.
            </b>   <br /><br />

</h1>
</p>
     <p  style={{color:"white"}}><h1>
     <br />

  <br /><br />
 But as a counter-narrative, tools like this reveal how technology often perpetuates and legitimizes discriminatory practices.

 </h1></p>

</div>


             <div class="textmagine" style={{backgroundColor:"#ffecc3"}}>

             <p align="center" style={{color:"#38717d"}}><h1>

             <br /> <br />
            <b> The judicial risk assessment flips the script, by subjecting those in power to technology that is typically reserved for only the poor and marginalized. In doing so, we aim to re-imagine the role that technology plays in addressing some of our toughest social challenges.
            <br />
          
            <br />

        <p align="center">    <h1><b>Check out the  <a href="http://rkarkarka.pythonanywhere.com/"><u>Judicial Risk Assesment Tool Demo</u></a></b>  </h1> </p>
</b>
</h1></p>
<div class="graphImage">
               <ImageTwo file="slide18.png" />


</div>
</div>
</div>
<p align="center" class="credit"><h1> Graphics by <a href="https://dribbble.com/arungaian"><b>Ian Arunga </b></a></h1></p>
<div class="small" >
<ImageTwo file="mitmedia.png" />
</div>







  </Layout>

)

export default IndexPage
